<template>
  <div class="card w-100">
    <div class="card-body">
      <p class="card-title">{{ title }}</p>
      <p v-if="subtitle !== ''" class="card-subtitle">{{ subtitle }}</p>
      <cs-loading v-wait:visible="'programs.load'" theme="success">
        Saving the information card progress
      </cs-loading>
    </div>
    <div v-wait:hidden="'programs.load'" class="list-group list-group-flush">
      <div v-for="program in programs" :key="'next-' + program.id">
        <cs-button :type="program.slug" :iconLeft="program.slug">
          <span v-html="program.name"
        /></cs-button>
        <cs-button
          v-for="year in registrationYears(program)"
          :type="program.slug"
          :iconLeft="program.slug"
          :key="'year-' + program.slug + '-' + year"
        >
          {{ schoolYear(year) }} Registration</cs-button
        >
      </div>
    </div>
    <div class="card-footer">
      <cs-button type="success" iconLeft="add-student"
        >Add another student</cs-button
      >
      <cs-button type="info" iconLeft="home">Home</cs-button>
    </div>
  </div>
</template>

<script>
import Member from '@/models/Member'
import Program from '@/models/Program'
import { isEmpty } from 'lodash/core'
import { schoolYear } from '@/utilities/dateFormatters'
import { API } from '@/utilities/http-common'
import moment from 'moment'
import Registration from '@/models/Registration'

export default {
  name: 'NextView',
  props: {
    title: {
      type: String,
      default: 'Next Activity'
    },
    subtitle: {
      type: String,
      default: ''
    },
    programSlug: {
      type: String,
      default: ''
    },
    information_card: {
      type: [Object, null],
      required: true
    }
  },
  created () {
    if (isEmpty(this.programs) && !this.$wait.waiting('programs.load')) {
      this.$wait.start('programs.load')
      API.get('programs')
        .then(result => {
          if (result.status === 200) {
            const programs = result.data.programs
            programs.forEach(function (program) {
              Program.insert({ data: program })
            })
            this.$wait.end('programs.load')
          }
        })
        .catch(e => {
          // eslint-disable-next-line
                  console.log(e);
          this.$wait.end('programs.load')
        })
    }
    if (
      isEmpty(this.registrations) &&
      !this.$wait.waiting('registrations.load')
    ) {
      this.$wait.start('registrations.load')
      API.get('registrations/active')
        .then(result => {
          if (result.status === 200) {
            const registrations = result.data.registrations
            registrations.forEach(function (registration) {
              Registration.insert({ data: registration })
            })
            this.$wait.end('registrations.load')
          }
        })
        .catch(e => {
          // eslint-disable-next-line
                  console.log(e);
          this.$wait.end('registrations.load')
        })
    }
  },
  computed: {
    member () {
      return Member.find(this.member_id)
    },
    programs () {
      const programs = Program.query()
      return programs
        .orderBy('sort')
        .where('active', true)
        .get()
    },
    registrations () {
      return Registration.all()
    },
    registrationsByProgramYear () {
      const registrations = this.registrations
      var by = registrations.reduce(function (r, registration) {
        r[registration.type] = r[registration.type] || Object.create(null)
        if (typeof r[registration.type][registration.year] === 'undefined') {
          r[registration.type][registration.year] = []
        }
        r[registration.type][registration.year].push(registration)
        return r
      }, Object.create(null))
      return by
    }
  },
  methods: {
    schoolYear,
    registrationYears (program) {
      const years = []
      const now = new moment()
      const thisYearStart = moment(program.registration_start)
      const thisYearEnd = moment(program.registration_end)
      const nextYearStart = moment(program.next_year_registration_start)
      const nextYearEnd = moment(program.next_year_registration_end)
      const year = program.current_year
      if (now.isBetween(thisYearStart, thisYearEnd, 'day')) {
        years.push(year)
      }
      if (now.isBetween(nextYearStart, nextYearEnd, 'day')) {
        years.push(year + 1)
      }
      return years
    }
  }
}
</script>

<style scoped></style>
